<!-- 选择租户 -->
<template>
  <el-dialog
      title="选择客户"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-select v-model="customerInfoId" placeholder="请选择客户" class="w-full">
      <el-option
          v-for="(item, index) in customerInfoList"
          :key="index"
          :label="item.name"
          :value="item.id"
      ></el-option>
    </el-select>
    <div
        slot="footer"
        class="dialog-footer flex flex-row align-items-center justify-between"
    >
      <div>
        <el-button class="justify-self-end" @click="close">取消</el-button>
        <el-button
            class="justify-self-end"
            type="primary"
            @click="submit"
            :disabled="!customerInfoId"
        >确定
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogVisible: false,
      customerInfoId: '',
      customerInfoList: []
    }
  },
  methods: {
    close () {
      this.customerInfoList = []
      this.customerInfoId = ''
      this.dialogVisible = false
    },
    show () {
      this.getCustomInfo()
      this.dialogVisible = true
    },
    getCustomInfo () {
      this.$api.page('customerInfo', {
        size: 999
      }).then(res => {
        this.customerInfoList = res.data.content
      })
    },
    submit () {
      // const result = this.customerInfoList.find(item => item.id === this.customerInfoId)
      this.$api.select('customerInfo', this.customerInfoId).then(res => {
        this.$emit('on-select', res.data.customerInfo)
      })
    }
  },
  mounted () {
  }
}
</script>
