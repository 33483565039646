<!--  -->
<template>
  <div>
    <blockTitle title="设备库"></blockTitle>
    <clientDevice
        @on-show-select-custom="selectCustomer"
        ref="clientDevice"
        :type="1"
        :customerInfo="customerInfo"
    ></clientDevice>
    <selectCustomerDialog
        @on-select="onSelectCustomer"
        ref="selectCustomerDialog"
    ></selectCustomerDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import clientDevice from 'components/client/clientDeviceV3'
import selectCustomerDialog from 'components/client/selectCustomerDialog'

export default {
  data () {
    return {
      customerInfo: {}
    }
  },
  components: {
    clientDevice,
    selectCustomerDialog
  },
  methods: {
    onSelectCustomer (customerInfo) {
      this.customerInfo = customerInfo
      this.$nextTick(() => {
        this.$router.push({
          name: 'clientAddDevice',
          query: {
            clientId: customerInfo.id,
            uniqueId: customerInfo.uniqueId
          }
        })
        this.$refs.selectCustomerDialog.close()
      })
    },
    selectCustomer () {
      this.$refs.selectCustomerDialog.show()
    }
  },
  mounted () {
  }
}
</script>
